export const DBNAME = 'BLBClientSideStorage';
export const DBVERSION = 1;
export const OBJECT_STORES = {
	cryptoKeys: 'CryptoKeys',
	wishlist: 'Wishlist',
	recentlyViewed: 'RecentlyViewed',
	sessionKeys: 'SessionKeys',
};
export const WAITME_CONFIG = {
	effect: 'bouncePulse',
	text: 'Veuillez patienter...',
	bg: 'rgba(0,0,0,0.5)',
	color: '#fff',
	maxSize: '',
	waitTime: -1,
	textPos: 'vertical',
	fontSize: '19pt',
	source: '',
};
